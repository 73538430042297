import React, { Suspense, createContext, useEffect, useState } from 'react'
import '../pages/main.scss'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetWithoutToken, GetData } from '../helpers/apiHandler'
import ProtectRoutes from '../auth/ProtectedRoutes'
import ProtectLoginRoutes from '../auth/ProtectedLoginRoute'
import { fetchCategories } from '../redux/actions/categoriesAction'
import { fetchCartItems, clearCartItems } from '../redux/actions/cartAction'
import { fetchLocations } from '../redux/actions/loactionActions'
import { deleteUser, verifyUser } from '../redux/actions/authActions'
import store from '../redux/store'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
// const MyDocument = React.lazy(() => import('../pages/ManageAccount/PdfViewer/PdfViewer'))
import ManageAccountDashboard from '../pages/ManageAccount/ManageAccountSideBar/Dashboard'
import WebsiteLayout from '../components/common/Layouts/WebsiteLayout'
import WebsiteLoginLayout from '../components/common/Layouts/WebsiteLoginLayout'
import PageNotFound404 from '../pages/NotFound/PageNotFound404'
import Skeleton from '../components/common/Skeleton/Skeleton'
import Loader from '../components/common/Loader/Loader'
// import HistoryPdf from '../pages/ManageAccount/OrderHistory/HistoryPdf'
// import DetailsPdf from '../pages/ManageAccount/OrderDetails/DetailsPdf'

// Pages imports
const Register = React.lazy(() => import('../pages/Register/Register'))
const AboutUS = React.lazy(() => import('../pages/AboutUs/AboutUs'))
const BlogHome = React.lazy(() => import('../pages/Blogs/BlogHome'))
const BlogDetails = React.lazy(() => import('../pages/Blogs/BlogDetails'))
const ForgotPassword = React.lazy(() =>
  import('../pages/Login/ForgetPassword/ForgetPassword')
)
const ForgotUserName = React.lazy(() => import('../pages/ForgotUserName'))
const ContactUs = React.lazy(() => import('../pages/ContactUs/ContactUs'))
const Checkout = React.lazy(() => import('../pages/Checkout/Checkout'))
const OrderSuccess = React.lazy(() =>
  import('../pages/Order/OrderSuccess/OrderSuccess')
)
const OrderFailure = React.lazy(() =>
  import('../pages/Order/OrderFailure/OrderFailure')
)
const AdvancedSearch = React.lazy(() =>
  import('../pages/Application Search/AdvancedSearch')
)
const LoginOtp = React.lazy(() => import('../pages/Login/LoginOtp/LoginOtp'))
const ThanksForApplying = React.lazy(() =>
  import('../pages/Register/ThanksForApplying/ThanksForApplying')
)
const Cart = React.lazy(() => import('../pages/Cart/Cart'))
const Category = React.lazy(() => import('../pages/Category/Category'))
const Dashboard = React.lazy(() =>
  import('../pages/ManageAccount/OrderHistory/Dashboard')
)
const AccountDetails = React.lazy(() =>
  import('../pages/ManageAccount/AccountDetails/AccountDetails')
)
const EditDetails = React.lazy(() =>
  import('../pages/ManageAccount/AccountDetails/EdittDetails/EditDetails')
)
const ChangePassword = React.lazy(() =>
  import('../pages/ManageAccount/ChangePassword/ChangePassword')
)
const LocationManagement = React.lazy(() =>
  import('../pages/ManageAccount/LocationManagement/LocationManagement')
)
const ManagePayments = React.lazy(() =>
  import('../pages/ManageAccount/ManagePayments/ManagePayments')
)
const FAQ = React.lazy(() => import('../pages/Faq/faq'))
const ProductPage = React.lazy(() =>
  import('../pages/Products/ProductPage/ProductPage')
)
const OrderDetails = React.lazy(() =>
  import('../pages/ManageAccount/OrderDetails/OrderDetails')
)
const Invoices = React.lazy(() => import('../pages/ManageAccount/Invoices/Invoices'))
const Reports = React.lazy(() =>
  import('../pages/ManageAccount/Reports/Reports')
)
const OrderCSV = React.lazy(() =>
  import('../pages/ManageAccount/BulkOrdering/OrderCSV')
)
const PreferredProduct = React.lazy(() =>
  import('../pages/ManageAccount/PreferredProducts/PreferredProduct')
)
const AddStaff = React.lazy(() =>
  import('../pages/ManageAccount/StaffManagement/AddStaff/AddStaff')
)
const EditStaff = React.lazy(() =>
  import('../pages/ManageAccount/StaffManagement/EditStaff/EditStaff')
)
const StaffDetails = React.lazy(() =>
  import('../pages/ManageAccount/StaffManagement/StaffDetails/StaffDetails')
)
const StaffManagement = React.lazy(() =>
  import('../pages/ManageAccount/StaffManagement/StaffManagement')
)
const StoreManager = React.lazy(() =>
  import('../pages/ManageAccount/StoreManager/StoreManager')
)
const SetPassword = React.lazy(() =>
  import('../pages/Login/SetPassword/SetPassword')
)
const Policies = React.lazy(() => import('../pages/Policies/Policies'))
const Login = React.lazy(() => import('../pages/Login/Login'))
const MasterLogin = React.lazy(() => import('../pages/Login/MasterLogin'))
const IndustrialLinks = React.lazy(() =>
  import('../pages/IndustrialLinks/IndustrialLinks')
)
const TechnicalBulletins = React.lazy(() => import('../pages/TechnicalBulletins/TechnicalBulletins'))
const Resetpassword = React.lazy(() =>
  import('../pages/Login/ResetPassword/ResetPassword')
)
const RegisterWD = React.lazy(() =>
  import('../pages/Register/RegisterWD/RegisterWD')
)
const Products = React.lazy(() => import('../pages/Products/Products'))
const WhyQls = React.lazy(() => import('../pages/AboutUs/WhyQls'))

const Home = React.lazy(() => import('../pages/HomePage/HomePage'))
const StaffAccountPricing = React.lazy(() =>
  import('../pages/ManageAccount/StaffAccountPricing/StaffAccountPricing')
)

export const LoaderContext = createContext()
export const FooterContext = createContext()
export const DiscountContext = createContext()
export const RouteContext = createContext()
export const SingleCheckoutContext = createContext()
export const SiteDataContext = createContext()
export const ScrollContext = createContext()

function Routes () {
  const state = store.getState()
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.auth)
  const locations = useSelector((state) => state.locations)
  const allowDropship = JSON.parse(localStorage?.getItem('profile'))?.allow_dropship
  const [checkOpenAccount, setCheckOpenAccount] = useState(false)
  const [allowedModules, setAllowedModules] = useState(JSON.parse(localStorage?.getItem('profile'))?.allowedModules)
  const [scrollState, setScrollState] = useState({
    menuOne: false,
    menuTwo: false,
    menuThree: false
  })
  const [discountParams, setDiscountParams] = useState({
    value: {
      automatic: '',
      manual: ''
    },
    type: {
      automatic: '',
      manual: ''
    },
    code: {
      automatic: { code: '' },
      manual: { code: '' }
    }
  })
  useEffect(() => {
    if (localStorage.getItem('token')) {
      const location = JSON.parse(localStorage.getItem('selectedLocation'))
    } else dispatch(fetchCategories(location?.id))
      GetWithoutToken('commonData').then((response) => {
        if (response.status === true) {
          setSiteDetails(response.result.siteData)
          localStorage.setItem('commonData', JSON.stringify(response.result))
        }
      })
  
    GetWithoutToken('getFirstService').then((response) => {
      if (response.status === true) {
        setFooterData(response.result)
      } else {
        toastr.error(response.message, 'Error')
      }
    }).catch(() => {
      toastr.error('Something went wrong', 'Error')
    })
    // if (state.auth.isAuthenticated) {
    //   dispatch(fetchCartItems())
    // }
  }, [])

  useEffect(() => {
    if (scrollState.menuOne || scrollState.menuTwo || scrollState.menuThree) {
      document.body.classList.add('my-class')
    } else {
      document.querySelector('body').classList.remove('my-class')
    }
  }, [scrollState])

  useEffect(() => {
    if (state.auth) {
      setAllowedModules(state?.auth?.user?.allowedModules)
    } else {
      setAllowedModules([])
    }
  }, [state.auth])

  useEffect(() => {
    if (state.auth.isAuthenticated) {
      dispatch(fetchLocations())
    }
  }, [userData.isAuthenticated])

  useEffect(() => {
    if (locations?.selectedLocation?.id) {
      const location = JSON.parse(localStorage.getItem('selectedLocation'))
      dispatch(fetchCartItems(locations?.selectedLocation?.id || location.id))
    }
  }, [locations])

  useEffect(() => {
    if (window.location.pathname !== '/login') {
      setRouteState(window.location.pathname)
    }
    if (localStorage.getItem('token') !== null) {
      GetData('customer/verifyCustomer')
        .then((response) => {
          if (response.status === true) {
            setCheckOpenAccount(response.result.customerDetails.isOpenAccount)
            dispatch(verifyUser())
          } else {
            localStorage.removeItem('profile')
            localStorage.removeItem('token')
            localStorage.removeItem('selectedLocation')
            dispatch(deleteUser())
            dispatch(clearCartItems())
          }
        })
        .catch((err) => {
          toastr.error(err, 'Error')
        })
    }
    window.scrollTo(0, 0)
    setScrollState({ menuOne: false, menuTwo: false, menuThree: false })
  }, [window.location.pathname])

  // useEffect(() => {
  //   const handleContextmenu = e => {
  //     e.preventDefault()
  //   }
  //   document.addEventListener('contextmenu', handleContextmenu)
  //   return function cleanup () {
  //     document.removeEventListener('contextmenu', handleContextmenu)
  //   }
  // }, [])

  const [loader, setLoader] = useState(false)
  const [footerState, setFooterState] = useState(true)
  const [routeState, setRouteState] = useState('/')
  const [singleCheckout, setSingleCheckout] = useState()
  const [siteDetails, setSiteDetails] = useState()
  const [footerData, setFooterData] = useState()
  const [applicationSearchCount, setApplicationSearchCount] = useState(0)
  const websiteLayoutRoutes = [
    '/',
    '/contact-us',
    '/cart',
    // '/checkout',
    '/order-successful',
    '/order-failure',
    '/application-search',
    '/all-categories',
    '/all-products',
    '/about-us',
    '/blogs',
    '/blogs/:slug',
    '/faqs',
    '/dashboard',
    '/account-details',
    '/location-management',
    '/manage-payments',
    '/edit-account-details',
    '/order-details/:id',
    '/reports',
    '/order-csv',
    '/staff-management',
    '/add-staff',
    '/edit-staff',
    '/staff-details/:id',
    '/store-manager',
    '/preferred-products',
    '/page/:policies',
    '/change-password',
    '/:category',
    '/:category/:subCategory',
    '/:category/:subCategory/:product',
    '/service-opportunities/:service',
    '/error-page',
    '/pdf',
    '/industry-links',
    '/order-history-pdf',
    '/details-pdf',
    '/staff-account-pricing'
  ]

  const websiteDashboardLayoutRoutes = [
    '/dashboard',
    '/account-details',
    '/location-management',
    '/manage-payments',
    '/edit-account-details',
    '/order-details/:id',
    '/reports',
    '/order-csv',
    '/staff-management',
    '/add-staff',
    '/edit-staff',
    '/staff-details/:id',
    '/store-manager',
    '/preferred-products',
    '/invoices',
    '/staff-account-pricing'
  ]

  const websiteLoginLayoutRoutes = [
    '/login',
    '/otp-login',
    '/forgot-password',
    '/reset-password',
    '/thanks-for-applying',
    '/forgot-username',
    '/verify-customer-email/:verificationToken',
    '/verify-staff-email/:verificationToken',
    '/master-login',
  ]
  return (
    <LoaderContext.Provider value={{ loader, setLoader }}>
      <SiteDataContext.Provider value={{ siteDetails, setSiteDetails, applicationSearchCount, setApplicationSearchCount }}>
        <FooterContext.Provider value={{ footerState, setFooterState, footerData, setFooterData }}>
          <RouteContext.Provider value={{ routeState, setRouteState, setAllowedModules }}>
            <SingleCheckoutContext.Provider
              value={{ singleCheckout, setSingleCheckout }}
            >
              <ScrollContext.Provider value={{ scrollState, setScrollState }}>
                <div className="App">
                  <Router>
                    <Loader show={loader} />
                    <Switch>
                    <Route path="/checkout" exact={true}>
                                <Suspense fallback={<Skeleton />}>
                                  <DiscountContext.Provider
                                    value={{ discountParams, setDiscountParams }}
                                  >
                                    <Checkout isOpenAccount={checkOpenAccount} />
                                  </DiscountContext.Provider>
                                </Suspense>
                    </Route>
                      <Route exact path={websiteLoginLayoutRoutes}>
                        <WebsiteLoginLayout>
                          <Switch>
                            <Route path="/login" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes component={Login} />
                              </Suspense>
                            </Route>
                            <Route path="/master-login" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes component={MasterLogin} />
                              </Suspense>
                            </Route>
                            <Route path="/otp-login" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes component={LoginOtp} />
                              </Suspense>
                            </Route>
                            <Route path="/forgot-password" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes
                                  component={ForgotPassword}
                                />
                              </Suspense>
                            </Route>
                            <Route path="/reset-password" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes component={Resetpassword} />
                              </Suspense>
                            </Route>
                            <Route path="/thanks-for-applying" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes
                                  component={ThanksForApplying}
                                />
                              </Suspense>
                            </Route>
                            <Route path="/forgot-username" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes
                                  component={ForgotUserName}
                                />
                              </Suspense>
                            </Route>
                            <Route
                              path="/verify-customer-email/:verificationToken"
                              exact={true}
                            >
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes component={SetPassword} />
                              </Suspense>
                            </Route>
                            <Route
                              path="/verify-staff-email/:verificationToken"
                              exact={true}
                            >
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes component={SetPassword} />
                              </Suspense>
                            </Route>
                          </Switch>
                        </WebsiteLoginLayout>
                      </Route>

                      <Route exact path={['/register', '/register-wd']}>
                        <WebsiteLoginLayout headerLogin={true}>
                          <Switch>
                            <Route path="/register" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes component={Register} />
                              </Suspense>
                            </Route>
                            <Route path="/register-wd" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectLoginRoutes component={RegisterWD} />
                              </Suspense>
                            </Route>
                          </Switch>
                        </WebsiteLoginLayout>
                      </Route>

                      <Route path={websiteLayoutRoutes}>
                        <WebsiteLayout isOpenAccount={checkOpenAccount}>
                          <Switch>
                            <Route path="/" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <Home />
                              </Suspense>
                            </Route>
                            <Route path="/error-page" exact={true}>
                              <PageNotFound404 />
                            </Route>
                            <Route path="/all-products" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <Products />
                              </Suspense>
                            </Route>
                            <Route path="/contact-us" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ContactUs />
                              </Suspense>
                            </Route>
                            <Route path="/industry-links" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <IndustrialLinks />
                              </Suspense>
                            </Route>
                            <Route path="/product-and-service-support" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <TechnicalBulletins />
                              </Suspense>
                            </Route>
                            <Route path="/cart" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <DiscountContext.Provider
                                  value={{ discountParams, setDiscountParams }}
                                >
                                  <Cart />
                                </DiscountContext.Provider>
                              </Suspense>
                            </Route>
                                                       {/* <Route path="/checkout" exact={true}>
                                <Suspense fallback={<Skeleton />}>
                                  <DiscountContext.Provider
                                    value={{ discountParams, setDiscountParams }}
                                  >
                                    <Checkout />
                                  </DiscountContext.Provider>
                                </Suspense>
                              </Route> */}
                            <Route path="/order-successful" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <OrderSuccess />
                              </Suspense>
                            </Route>
                            <Route path="/order-failure" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <OrderFailure />
                              </Suspense>
                            </Route>
                            <Route path="/application-search" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <AdvancedSearch />
                              </Suspense>
                            </Route>
                            <Route path="/all-categories" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <Category />
                              </Suspense>
                            </Route>
                            <Route path="/about-us" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <AboutUS />
                              </Suspense>
                            </Route>
                            <Route path="/blogs" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <BlogHome />
                              </Suspense>
                            </Route>
                            {/* <Route path="/blogs/:category" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <BlogHome />
                              </Suspense>
                            </Route> */}
                            <Route path="/blogs/:slug" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <BlogDetails />
                              </Suspense>
                            </Route>
                            <Route path="/faqs" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <FAQ />
                              </Suspense>
                            </Route>

                            <Route path={websiteDashboardLayoutRoutes}>
                              <ManageAccountDashboard openAccount={checkOpenAccount}>
                                <Switch>
                                <Route path="/dashboard" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={Dashboard}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        (x.permission === 'Order Management' || x.permission === 'Staff Order Management') &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/invoices" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={Invoices}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Order Management' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/account-details" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={AccountDetails}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Account Details' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/location-management" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={LocationManagement}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission ===
                                          'Location Management' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/staff-account-pricing" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={StaffAccountPricing}
                                  staffRoute={true}
                                  allowed={allowDropship}
                                />
                              </Suspense>
                            </Route>
                            <Route path="/manage-payments" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={ManagePayments}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Payment Methods' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/edit-account-details" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={EditDetails}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Account Details' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/order-details/:id">
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={OrderDetails}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        (x.permission === 'Order Management' || x.permission === 'Staff Order Management') &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/reports" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={Reports}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Reports' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/order-csv" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes component={OrderCSV} />
                              </Suspense>
                            </Route>
                            <Route path="/staff-management" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={StaffManagement}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Order Management' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission ===
                                          'Location Management' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Account Details' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Payment Methods' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Reports' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/add-staff" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={AddStaff}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Order Management' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission ===
                                          'Location Management' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Account Details' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Payment Methods' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Reports' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/edit-staff" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={EditStaff}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Order Management' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission ===
                                          'Location Management' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Account Details' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Payment Methods' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Reports' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/staff-details/:id" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={StaffDetails}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Order Management' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission ===
                                          'Location Management' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Account Details' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Payment Methods' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Reports' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/store-manager" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes
                                  component={StoreManager}
                                  staffRoute={true}
                                  allowed={
                                    allowedModules !== undefined &&
                                    allowedModules.length > 0 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Order Management' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission ===
                                          'Location Management' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Account Details' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Payment Methods' &&
                                        x.isAccessible === 1
                                    ) !== -1 &&
                                    allowedModules.findIndex(
                                      (x) =>
                                        x.permission === 'Reports' &&
                                        x.isAccessible === 1
                                    ) !== -1
                                  }
                                />
                              </Suspense>
                            </Route>
                            <Route path="/preferred-products" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes component={PreferredProduct} />
                              </Suspense>
                            </Route>
                                </Switch>
                              </ManageAccountDashboard>
                            </Route>
                            <Route path="/page/:policies" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <Policies />
                              </Suspense>
                            </Route>
                            {/* <Route path='/pdf' exact={true}>
                        <MyDocument />
                      </Route> */}
                            {/* <Route path='/order-history-pdf' exact={true}>
                       <HistoryPdf />
                      </Route> */}
                            {/* <Route path='/details-pdf'>
                        <DetailsPdf />
                      </Route> */}
                            <Route path="/change-password" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <ProtectRoutes component={ChangePassword} />
                              </Suspense>
                            </Route>
                            <Route path="/service-opportunities/:service" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <WhyQls />
                              </Suspense>
                            </Route>
                            <Route path="/:category" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <Products />
                              </Suspense>
                            </Route>
                            <Route path="/:category/:subCategory" exact={true}>
                              <Suspense fallback={<Skeleton />}>
                                <Products />
                              </Suspense>
                            </Route>
                            <Route
                              path="/:category/:subCategory/:product"
                              exact={true}
                            >
                              <Suspense fallback={<Skeleton />}>
                                <ProductPage />
                              </Suspense>
                            </Route>
                          </Switch>
                        </WebsiteLayout>
                      </Route>
                      <Route>
                        <WebsiteLayout>
                          <PageNotFound404 />
                        </WebsiteLayout>
                      </Route>
                    </Switch>
                  </Router>
                </div>
              </ScrollContext.Provider>
            </SingleCheckoutContext.Provider>
          </RouteContext.Provider>
        </FooterContext.Provider>
      </SiteDataContext.Provider>
    </LoaderContext.Provider>
  )
}

export default Routes
